import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
import { TwitterTweetEmbed } from 'react-twitter-embed';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`It's been a while since I last wrote a blog post - I've been busy growing the SDK team and working on my new `}<a parentName="p" {...{
        "href": "https://forum.dfinity.org/t/payments-invoice-canister-design-review/9843"
      }}>{`Invoice Canister`}</a>{` project. The project ends up scaling to be pretty complex, since I want it to be able to abstract every token ledger on the IC with a single API, and so it led to a larger Motoko codebase than I've written before. As a result, that led me to want a better pattern for testing my code.`}</p>
    <h2 {...{
      "id": "credit-where-its-due"
    }}><a parentName="h2" {...{
        "href": "#credit-where-its-due"
      }}>{`Credit where it's due`}</a></h2>
    <p>{`After tweeting about my frustrations finding a viable testing pattern, `}<a parentName="p" {...{
        "href": "https://paulyoung.me/"
      }}>{`Paul Young`}</a>{` tweeted about a old testing framework he came up with that has since been removed from the `}<a parentName="p" {...{
        "href": "https://github.com/dfinity/motoko-base"
      }}>{`Motoko Base Library`}</a>{`. `}</p>
    <TwitterTweetEmbed tweetId={"1484700207342239745"} mdxType="TwitterTweetEmbed" />
    <p>{`Paul linked to a small library called ActorSpec, which is designed to execute batches of tests, grouped by `}<inlineCode parentName="p">{`describe`}</inlineCode>{` and `}<inlineCode parentName="p">{`it`}</inlineCode>{` blocks, which feels fairly similar to Jest syntax.`}</p>
    <h2 {...{
      "id": "setting-it-up"
    }}><a parentName="h2" {...{
        "href": "#setting-it-up"
      }}>{`Setting it up`}</a></h2>
    <p>{`To get started, you'll need to install following the directions at `}<a parentName="p" {...{
        "href": "https://github.com/dfinity/vessel"
      }}>{`https://github.com/dfinity/vessel`}</a>{`.`}</p>
    <h3 {...{
      "id": "configuring-your-project"
    }}><a parentName="h3" {...{
        "href": "#configuring-your-project"
      }}>{`Configuring your project`}</a></h3>
    <p>{`You can find reference code for this next step at `}<a parentName="p" {...{
        "href": "https://github.com/krpeacock/motoko-unit-tests"
      }}>{`https://github.com/krpeacock/motoko-unit-tests`}</a>{`. There, you will find examples of a `}<inlineCode parentName="p">{`makefile`}</inlineCode>{`, the `}<inlineCode parentName="p">{`vessel.dhall`}</inlineCode>{` and `}<inlineCode parentName="p">{`package-set.dhall`}</inlineCode>{` files that you will need to get your test suite running.`}</p>
    <pre {...{
      "className": "language-yaml"
    }}><code parentName="pre" {...{
        "className": "language-yaml"
      }}><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` vessel.dhall
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` dependencies = `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"base"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"matchers"`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` compiler = Some "0.6.21" `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}</code></pre>
    <pre {...{
      "className": "language-yaml"
    }}><code parentName="pre" {...{
        "className": "language-yaml"
      }}><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` package`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{`set.dhall
let upstream =
      https`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`//github.com/dfinity/vessel`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{`package`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{`set/releases/download/mo`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{`0.6.20`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{`20220131/package`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{`set.dhall
in  upstream

`}</code></pre>
    <pre {...{
      "className": "language-swift"
    }}><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`# `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Makefile`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`PHONY`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` test

test`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
    $`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`shell vessel bin`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span>{`moc `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span>{`r $`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`shell vessel sources`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span>{`wasi`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span>{`system`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span>{`api test`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`/*`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Test`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`mo

`}</code></pre>
    <p>{`With those files in the root of your project, you can now run `}<inlineCode parentName="p">{`vessel install`}</inlineCode>{`, and then you should be good to go with writing your tests.`}</p>
    <h2 {...{
      "id": "writing-tests"
    }}><a parentName="h2" {...{
        "href": "#writing-tests"
      }}>{`Writing tests`}</a></h2>
    <p>{`In my case, I have added a `}<inlineCode parentName="p">{`Test.mo`}</inlineCode>{` file that contains my full suite of unit tests. By moving the core logic out of `}<inlineCode parentName="p">{`main.mo`}</inlineCode>{` into the `}<inlineCode parentName="p">{`Utils.mo`}</inlineCode>{` file, I can use consistent arguments in a `}<inlineCode parentName="p">{`Types.mo`}</inlineCode>{` file for my canister's methods, and then test all the functional logic directly out of `}<inlineCode parentName="p">{`Utils.mo`}</inlineCode>{`.`}</p>
    <p>{`With that, I can use a nice syntax of describe function calls that pass `}<inlineCode parentName="p">{`do {}`}</inlineCode>{` blocks that evaluate to true or false. We can then test the functions with various inputs against their expected outputs in under a second (depending on the extent of the functionality).`}</p>
    <pre {...{
      "className": "language-swift"
    }}><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`# `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Test`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`mo
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`U`}</span>{`  `}<span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"../src/example/Utils"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Debug`}</span>{` `}<span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"mo:base/Debug"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`ActorSpec`}</span>{` `}<span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"./utils/ActorSpec"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
type `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Group`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`ActorSpec`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Group`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` assertTrue `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`ActorSpec`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`assertTrue`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` describe `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`ActorSpec`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`describe`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` it `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`ActorSpec`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`it`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` skip `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`ActorSpec`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`skip`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` pending `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`ActorSpec`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`pending`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` run `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`ActorSpec`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`run`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` success `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`run`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`
  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`describe`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"Example Test Suite"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`
    `}<span parentName="code" {...{
          "className": "token function"
        }}>{`describe`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"Subgroup"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`
      `}<span parentName="code" {...{
          "className": "token function"
        }}>{`it`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"should assess a boolean value"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`do`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token function"
        }}>{`assertTrue`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token boolean"
        }}>{`true`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token function"
        }}>{`it`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"should sum two positive Nats"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`do`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token function"
        }}>{`assertTrue`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`U`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`sum`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`2`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`==`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`3`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token function"
        }}>{`it`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"should fail a check that doesn't match"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`do`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token function"
        }}>{`assertTrue`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`U`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`sum`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`2`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`==`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`4`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token function"
        }}>{`skip`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"should skip a test"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`do`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Useful for defining a test that is not yet implemented`}</span>{`
        `}<span parentName="code" {...{
          "className": "token boolean"
        }}>{`true`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`success `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`==`}</span>{` `}<span parentName="code" {...{
          "className": "token boolean"
        }}>{`false`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Debug`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`trap`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"Tests failed"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`


`}</code></pre>
    <h2 {...{
      "id": "some-limitations"
    }}><a parentName="h2" {...{
        "href": "#some-limitations"
      }}>{`Some limitations`}</a></h2>
    <p>{`The main limits here are that unit tests will not interact with core replica features. Here is some functionality you will not be able to handle with unit tests in the current state:`}</p>
    <ul>
      <li parentName="ul">{`Time`}</li>
      <li parentName="ul">{`Calls for randomness`}</li>
      <li parentName="ul">{`Inter-canister calls`}</li>
      <li parentName="ul">{`Managing state`}</li>
      <li parentName="ul">{`Upgrade hooks`}</li>
    </ul>
    <p>{`For this sort of functionality, I recommend writing end-to-end tests, deploying to alternate "staging" canisters before production, and automating your deployment scripts for consistency.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      